import React, { useState, CSSProperties } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { merge } from "ramda"
import { format, allTrue } from "../../services/utils"

import Typography from "@material-ui/core/Typography"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableFooter from "@material-ui/core/TableFooter"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Checkbox from "@material-ui/core/Checkbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import useTheme from "@material-ui/styles/useTheme"
import {
  StyledTableContainer,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  StyledCircularProgress,
} from "./styles"

import TablePagination from "./Pagination"
import Row from "./Row"

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const DEFAULT_OPTIONS = {
  paging: false,
  sorting: false,
  total: false,
  selection: false,
  selectAll: true,
  selectRow: undefined,
}

/**
 * Base table component - used to render a table with a header and a body.
 *
 *
 * @param {Object} props
 *
 * @param {Array<{
 *          title?: string,
 *          field: string,
 *          type?: 'date' | 'document' | 'phone' | 'decimal' | 'currency' | 'integer',
 *          render?: function,
 *          getTotal?: function,
 *          align?: string,
 *          role?: string,
 *          sortBy?: string,
 *          sortable?: boolean,
 * }>} props.columns - Table columns definition
 *
 * @param {Array<Object>} props.data - Table data
 * @param {(row: unknown) => CSSProperties} props.rowStyle - TableRowStyle
 * @param {({row: unknown, column: unknown}) => CSSProperties} props.cellStyle - TableCellStyle
 * @param {(renderProps: unknown) => JSX.Element} props.detailPanel - Component that will be rendered below each line
 * @param {(row: unknown) => boolean} props.getShowDetailPanel - function that returns boolean value, used to show detail panel open button
 *
 */
export default function BaseTable(props) {
  const {
    title,
    columns,
    data,
    options: tableOptions,
    actions,
    page,
    rowsPerPage,
    count,
    onPageChange,
    onRowsPerPageChange,
    currentSort,
    onSortChange,
    loading,
    autoSizing,
    hover,
    selected,
    onSelect,
    detailPanel,
    getShowDetailPanel,
    rowStyle,
    cellStyle,
    ...tableProps
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [actionMenu, setActionMenu] = useState({
    currentRow: null,
    anchorEl: null,
  })

  const handleOpenMenu = (e, currentRow) => {
    setActionMenu({
      anchorEl: e.currentTarget,
      currentRow,
    })
  }

  const handleClose = () =>
    setActionMenu({
      currentRow: null,
      anchorEl: null,
    })

  const handleAction = (action) => {
    handleClose()
    action.onClick?.(actionMenu.currentRow)
  }

  const handleSelectAll = (event) => {
    try {
      const checked = event.target.checked
      if (checked) onSelect(data)
      else onSelect([])
    } catch (error) {
      console.error("You need to provide the 'onSelect' callback!")
    }
  }

  const handleSelectRow = (_, row) => {
    try {
      const selectedIndex = selected.indexOf(row)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      onSelect(newSelected, row)
    } catch (error) {
      console.error(
        onSelect ? `${error}` : "You need to provide the 'onSelect' callback!"
      )
    }
  }

  const options = merge(DEFAULT_OPTIONS, tableOptions)

  const renderHead = () => {
    const isActive = (field) => field === currentSort.field

    const Headers = columns.map((column, columnIndex) => {
      const sortable = options.sorting && column.sortable !== false

      return (
        <StyledTableCell
          role={column.role}
          align={column.align}
          key={columnIndex}
        >
          {sortable ? (
            <TableSortLabel
              active={isActive(column.field)}
              direction={currentSort.order}
              onClick={() => onSortChange(column.field, column.sortBy)}
            >
              {column.title}
            </TableSortLabel>
          ) : (
            column.title
          )}
        </StyledTableCell>
      )
    })

    // =========================
    // ==== TABLE SELECTION ====
    // =========================

    if (options.selection) {
      const numSelected = selected.length
      const rowsCount = data.length

      const indeterminate = numSelected > 0 && numSelected < rowsCount
      const checked = rowsCount > 0 && numSelected === rowsCount

      Headers.unshift(
        <StyledTableCell padding="checkbox" key="checkbox">
          {options.selectAll && (
            <Checkbox
              color="primary"
              indeterminate={indeterminate}
              checked={checked}
              onChange={handleSelectAll}
              inputProps={{ "aria-label": "select all rows" }}
            />
          )}
        </StyledTableCell>
      )
    }

    // ===============
    // ==== PANEL ====
    // ===============

    if (detailPanel !== undefined) {
      Headers.unshift(
        <StyledTableCell colSpan={1} key="actions" role="action" />
      )
    }

    if (actions) {
      Headers.push(
        <StyledTableCell colSpan={actions.length} key="actions" role="action" />
      )
    }

    return Headers
  }

  const renderRows = () => {
    const Rows = data.map((row, rowIndex) => (
      <Row
        key={rowIndex}
        data={row}
        columns={columns}
        selected={selected}
        options={options}
        rowStyle={rowStyle}
        cellStyle={cellStyle}
        detailPanel={detailPanel}
        getShowDetailPanel={getShowDetailPanel}
        hover={hover}
        actions={actions}
        isMobile={isMobile}
        onSelectRow={handleSelectRow}
        onOpenMenu={handleOpenMenu}
        size={tableProps.size}
      />
    ))

    return Rows
  }

  const renderFooter = () => {
    const Footers = columns.map((column, columnIndex) => {
      const defaultValue = column.type === "decimal" ? 0 : ""

      const footerContent = column.getTotal
        ? column.getTotal({ column, data })
        : defaultValue

      return (
        <StyledTableCell
          role={column.role}
          align={column.align}
          key={columnIndex}
        >
          {React.isValidElement(footerContent)
            ? footerContent
            : format(column.type, footerContent)}
        </StyledTableCell>
      )
    })

    if (detailPanel !== undefined) {
      Footers.unshift(
        <StyledTableCell colSpan={1} key="actions" role="action" />
      )
    }

    if (actions) {
      Footers.push(
        <StyledTableCell colSpan={actions.length} key="actions" role="action" />
      )
    }

    return Footers
  }

  return (
    <StyledTableContainer
      className={clsx({ loading, empty: data?.length === 0, autoSizing })}
    >
      {title && (
        <Typography
          component="h6"
          variant="subtitle1"
          color="primary"
          style={{
            padding: "12px",
            paddingBottom: "0.25rem",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      )}
      <StyledTable {...tableProps}>
        <TableHead>
          <StyledTableRow>{renderHead()}</StyledTableRow>
        </TableHead>
        <TableBody>{renderRows()}</TableBody>
        {options.total && (
          <TableFooter>
            <StyledTableRow>{renderFooter()}</StyledTableRow>
          </TableFooter>
        )}
      </StyledTable>
      {options.paging && (
        <TablePagination
          size={tableProps.size}
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
      {allTrue([isMobile, actions?.length > 0]) && (
        <Menu
          keepMounted
          anchorEl={actionMenu.anchorEl}
          open={Boolean(actionMenu.anchorEl)}
          onClose={handleClose}
        >
          {actions.map((action, actionIndex) => (
            <MenuItem
              dense
              key={"menu-action-" + actionIndex}
              onClick={() => handleAction(action)}
              disabled={
                action.getDisabled && actionMenu.currentRow
                  ? action.getDisabled(actionMenu.currentRow)
                  : false
              }
            >
              <>
                {typeof action.title === "function"
                  ? action.title(actionMenu.currentRow)
                  : action.title}
              </>
            </MenuItem>
          ))}
        </Menu>
      )}

      {loading && <StyledCircularProgress size={50} />}
    </StyledTableContainer>
  )
}

BaseTable.propTypes = {
  //  Table
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      field: PropTypes.string.isRequired,
      align: PropTypes.string,
      role: PropTypes.string,
      type: PropTypes.oneOf([
        "date",
        "document",
        "phone",
        "decimal",
        "currency",
        "integer",
      ]),
      sortable: PropTypes.bool,
      sortBy: PropTypes.string,
      render: PropTypes.func,
      getTotal: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  rowStyle: PropTypes.func,
  cellStyle: PropTypes.func,

  // Options
  options: PropTypes.shape({
    paging: PropTypes.bool,
    sorting: PropTypes.bool,
    total: PropTypes.bool,
    selection: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectRow: PropTypes.func,
  }),

  // Actions
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
      ]).isRequired,
      onClick: PropTypes.func.isRequired,
      size: PropTypes.oneOf(["small", "medium", "large"]),
      icon: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.func,
        PropTypes.object,
      ]),
      content: PropTypes.func,
      getDisabled: PropTypes.func,
    })
  ),

  // Pagination props
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  count: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,

  // Sorting props
  currentSort: PropTypes.shape({
    field: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
  }),
  onSortChange: PropTypes.func,

  // Booleans
  loading: PropTypes.bool,
  autoSizing: PropTypes.bool,
  hover: PropTypes.bool,

  // Selection
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  detailPanel: PropTypes.func,
}

// Default props
BaseTable.defaultProps = {
  options: DEFAULT_OPTIONS,
  loading: false,
  autoSizing: true,
  hover: true,
  selected: [],
}
